





















import Vue from 'vue'

import {
  BCard,
} from 'bootstrap-vue'

import EmailTemplateForm from '@/views/admin/emails/email-forms/EmailTemplateForm.vue'
import { appEmailItem } from '@/store/modules/app-email-item'

export default Vue.extend({
  name: 'EmailAdd',
  components: {
    BCard,

    EmailTemplateForm,
  },
  computed: {
    emailTemplate() {
      return appEmailItem.getters.getEmailTemplate
    },
    isButtonDisabled() {
      return appEmailItem.getters.getIsButtonDisabled
    },
    getFolderId() {
      return appEmailItem.getters.getFolderId
    },
  },
  async created() {
    await this.setFolderId()
  },
  beforeDestroy() {
    appEmailItem.mutations.RESET_EMAIL_TEMPLATE()
  },
  methods: {
    async setFolderId() {
      const folderId = this.$router.currentRoute.query.folder_id
      appEmailItem.mutations.SET_FOLDER_ID(folderId)
    },
    async createTemplate() {
      this.emailTemplate.folder_id = this.getFolderId

      await appEmailItem.actions.createTemplate()
      this.goBack()
    },
    goBack() {
      this.$router.push({ name: 'admin-email-templates-folders', query: { folder_id: this.getFolderId } })
    },
  },
})
