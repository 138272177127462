
































































































import Vue from 'vue'
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import { tinyOptions } from '@core/mixins/tinyOptions'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default Vue.extend({
  name: 'EmailTemplateForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    Editor,

    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  props: {
    mode: {
      type: String,
      required: true,
    },
    emailTemplate: {
      type: Object,
      required: true,
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    canSaveChanges() {
      if (this.mode === 'edit') {
        return this.$can('update', permissionSubjects.EmailTemplate)
      }
      return true
    },
  },
  methods: {
    insertValueToEditor(val: string) {
      getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
    },
    saveChanges() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        (this.$refs.emailTemplateRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            this.$emit('save-changes')
          } else {
            reject()
          }
        })
      })
    },
  },
})
