import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { EmailItemState } from './types'
import { IEmailTemplate } from '@/views/admin/emails/types'
import { createEmailTemplate, fetchEmailTemplate, updateEmailTemplate } from '@/api/emailTemplates'

// eslint-disable-next-line import/prefer-default-export
export const appEmailItem = createModule(
  'appEmailItem',
  {
    namespaced: true,
    state: {
      isLoading: false,
      emailTemplate: {
        name: '',
        subject: '',
        body: '',
        folder_id: null,
      },
      isButtonDisabled: false,
      folderId: null,
    } as EmailItemState,
    getters: {
      getIsLoading: state => state.isLoading,
      getEmailTemplate: state => state.emailTemplate,
      getIsButtonDisabled: state => state.isButtonDisabled,
      getFolderId: state => state.folderId,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_FOLDER_ID(state, val: string | (string | null)[] | null | undefined) {
        state.folderId = val
      },
      SET_EMAIL_TEMPLATE(state, val: IEmailTemplate) {
        state.emailTemplate = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      RESET_EMAIL_TEMPLATE(state) {
        state.emailTemplate = {
          name: '',
          subject: '',
          body: '',
        }
      },
    },
    actions: {
      async createTemplate({ state }) {
        try {
          appEmailItem.mutations.SET_IS_BUTTON_DISABLED(true)
          const response = await createEmailTemplate(state.emailTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Email was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appEmailItem.mutations.SET_IS_BUTTON_DISABLED(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appEmailItem.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async setEmailTemplate(ctx, id) {
        try {
          appEmailItem.mutations.SET_IS_LOADING(true)
          const response = await fetchEmailTemplate(id)
          appEmailItem.mutations.SET_EMAIL_TEMPLATE(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching email item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appEmailItem.mutations.SET_IS_LOADING(false)
        }
      },
      async updateTemplate({ state }) {
        try {
          const updatedEmailTemplate = {
            id: state.emailTemplate.id,
            name: state.emailTemplate.name,
            subject: state.emailTemplate.subject,
            body: state.emailTemplate.body,
          }

          appEmailItem.mutations.SET_IS_BUTTON_DISABLED(true)

          await updateEmailTemplate(updatedEmailTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email Template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appEmailItem.mutations.SET_IS_BUTTON_DISABLED(false)
          router.push({ name: 'admin-email-templates-folders' })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Email Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appEmailItem.register(store)
