import axios from '@axios'

export const fetchEmailTemplatesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/email-templates', { params })
  return res
}

export const fetchEmailTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/email-templates/${id}`)
  return res
}

export const createEmailTemplate = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/email-templates', params)
  return res
}

export const updateEmailTemplate = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/email-templates/${params.id}`, params)
  return res
}

export const deleteEmailTemplate = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/email-templates/${id}`)
  return res
}

export const cloneEmailTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`auth/email-templates/${id}/clone`)
  return res
}
